@use "mixins" as *;
.car__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem .4rem;
    overflow-y: auto;
    overflow-x: hidden;

    &.-incharge {
        padding: 1rem 0rem;
    }
}

.car__state {
    @include title;
    display: flex;
    width: 100%;
    margin: 0;
    margin-left: 1rem;

    &.-hidden {
        display: none;
    }
}

.car__state-list {
    display: flex;
    width: 100%;
    flex-direction: column;

    &.-new {
        margin-top: -1rem;
    }
}

.car__toolbar-search {
    display: flex;
    min-width: 12rem;
    background-color: var(--content-bg--color-lighter);
    justify-content: space-between;
    align-items: center;
    padding-left: .6rem;
    overflow: hidden;
    border: .4px solid var(--color-border-secondary);
    border-radius: var(--radius);
    gap: .6rem;
}

.car__toolbar-search:focus-within {
    border-color: var(--color-border-focus);
}

.car__toolbar-icon {
    width: 1rem;
    height: 1rem;
    color: var(--color-icon);
    padding: .1rem;

    &.-button {
        cursor: pointer;
    }

    &.-button:hover {
        cursor: pointer;
        color: var(--color-text-quaternary);
    }
}

.car__toolbar-search:focus-within .car__toolbar-icon {
    color: var(--color-icon-focus);
}

.body__toolbar-button:hover .car__toolbar-icon {
    color: var(--color-icon-focus);
}

.car__toolbar-search-area {
    @include body;
    display: flex;
    width: 100%;
    background-color: var(--content-bg--color-lighter);
    border: none;
    resize: none;
    outline: none;
    color: var(--color-text-primary);
}

.car__states {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.car__state-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-button-bg-submit);
    color: var(--color-text-primary);
    padding: .2rem .8rem;
    border-radius: var(--radius-max);
    gap: .4rem;

    &.-hidden {
        display: none;
    }
}

.car__state-toolbar-text {
    @include body;
    margin: 0;
    color: var(--color-text-quaternary);
}

.state__choice {
    cursor: pointer;

    &:hover {
        color: var(--color-text-submit);
    }

    &.-hidden {
        display: none;
    }
}

.car__state-remove {
    padding: 0;

    &:hover {
        background-color: transparent;
    }
}

#cars-app {
  height: 100%;
}

.p-button-text.p-button-secondary:not(:disabled):hover {
  background: var(--color-button-bg-focus);
  border: none;
  color: var(--color-text-primary);
}

.p-togglebutton-checked > .p-togglebutton-content > .car__card-state.-box-shadow {
  box-shadow: var(--p-overlay-popover-shadow);
}
